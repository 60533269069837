import React, { FC } from 'react';
import { Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { FooterProps } from './models';

import './Footer.scss';

const Footer: FC<FooterProps> = ({
  data: { logo, socialMedia, contact, phone, email, address, legals },
}) => (
  <footer id="footer">
    <div className="container pt-5">
      <div className="row align-items-end justify-content-center justify-content-lg-around">
        <div className="col-12 col-lg-2 text-center align-self-center">
          <img src={logo.url} alt={logo.altText} />
        </div>
        <div className="col-12 col-lg-5 mr-auto">
          <h3 className="text-danger">
            <i>{contact}</i>
          </h3>
          <h5 className="text-danger">
            <DangerouslySetInnerHtml html={phone} element="em" />
          </h5>
          <a href={`mailto:${email}`}>{email}</a>
          <address>{address}</address>
        </div>
        <div className="col-12 col-lg-5 text-lg-right">
          <div className="mb-3">
            {socialMedia?.map(({ icon, link }) => (
              <Link
                to={link.url}
                target="_blank"
                data-testid="footer-nav-item"
                className="footer__social-item px-2"
                rel="noreferrer"
                key={icon}
              >
                <small>
                  <i className={`fab fa-${icon}`} />
                  <b className="ms-1">{link.name}</b>
                </small>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="legals text-center mt-5 py-4">
      <strong>
        <DangerouslySetInnerHtml html={legals} element="small" />
      </strong>
    </div>
  </footer>
);

export default Footer;
