import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';

import 'styles/main.scss';

import OneTrustScript from 'common/OneTrust/OneTrustScript';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Loadable from 'components/Loadable';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, headerTransparent, className, alterLogo }) => {
  const { header, footer, siteSettings, global, homepage } = useStaticQuery(graphql`
    {
      homepage {
        oneTrustScriptId
      }
      header {
        logo {
          url
          altText
        }
        navLeft {
          name
          url
        }
        navRight {
          name
          url
        }
        brand {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 210, quality: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 210, quality: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 210, quality: 100)
            }
          }
        }
        contact {
          name
          url
        }
      }
      footer {
        logo {
          url
          altText
        }
        socialMedia {
          icon
          link {
            name
            url
          }
        }
        contact
        phone
        email
        address
        legals
      }
      siteSettings {
        siteName
      }
      global {
        search {
          name
          url
        }
      }
    }
  `);

  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });

  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  return (
    <div className={CSSclassNames}>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      <div id="site">
        <Header
          data={header}
          settings={siteSettings}
          search={global.search}
          alterLogo={alterLogo}
        />
        {homepage.oneTrustScriptId && <OneTrustScript oneTrustId={homepage.oneTrustScriptId} />}
        <main id="main" className="layout__main">
          {children}
        </main>
        <Footer data={footer} />
      </div>
    </div>
  );
};

export default Layout;
