import React, { FC } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, navigate } from 'gatsby';

import Image from 'common/Image';

import { HeaderProps } from './models';

import './Header.scss';

const Header: FC<HeaderProps> = ({
  data: { logo, navLeft, navRight, contact, brand },
  settings: { siteName },
  search,
  alterLogo,
}) => {
  const searchRef = React.createRef<HTMLInputElement>();
  const [expanded, setExpanded] = React.useState(false);
  const handleSearch = () => {
    if (searchRef.current && searchRef.current.value.length > 0) {
      navigate(search.url, { state: { keyword: searchRef.current.value } });
    }
  };

  return (
    <header id="header" className="w-100">
      <Navbar expand="xl" className="w-100 py-0" expanded={expanded}>
        <Navbar.Brand href="/" className="p-1 d-block d-xl-none">
          {alterLogo ? (
            <Image imageData={alterLogo} alt={siteName} />
          ) : (
            <img src={logo.url} alt={logo.altText} />
          )}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
          className="border-0"
        >
          <span className="fas fa-bars" />
        </Navbar.Toggle>
        <Navbar.Collapse
          id="menu"
          className="px-2 pt-4 px-xl-0 pt-xl-0 d-xl-flex justify-align-center"
        >
          <Nav className="navbar-nav navbar-nav-menu justify-content-end w-100">
            <div className="navbar-nav-side-l px-2 pb-2 pb-xl-0">
              <Image imageData={brand} alt={siteName} />
            </div>
            <div className="w-100 d-block d-xl-flex justify-content-end align-items-center">
              {navLeft.map((navItem) => (
                <Link
                  to={navItem.url}
                  key={navItem.name}
                  className="text-start text-xl-center px-2 px-xl-3 nav-link"
                  title={navItem.name}
                  data-testid="header-nav-left-link"
                  onClick={() => setExpanded(!expanded)}
                >
                  <span>{navItem.name}</span>
                </Link>
              ))}
            </div>
          </Nav>
          <div className="navbar-nav-logo d-none d-xl-block">
            <Link to="/">
              {alterLogo ? (
                <Image imageData={alterLogo} alt={siteName} />
              ) : (
                <img src={logo.url} alt={logo.altText} />
              )}
            </Link>
          </div>
          <Nav className="navbar-nav navbar-nav-menu w-100">
            <div className="w-100 d-block d-xl-flex align-items-center">
              {navRight.map((navItem) => (
                <Link
                  to={navItem.url}
                  key={navItem.name}
                  className="text-start text-xl-center px-2 px-xl-3 nav-link"
                  title={navItem.name}
                  data-testid="header-nav-right-link"
                  onClick={() => setExpanded(!expanded)}
                >
                  <span>{navItem.name}</span>
                </Link>
              ))}
            </div>
            <div className="navbar-nav-side-r d-xl-flex align-items-center justify-content-end px-2 py-2">
              <div className="text-start text-md-end me-2">
                <div className="d-flex mb-2 align-items-center" id="header__search">
                  <button
                    type="button"
                    className="bg-transparent border-0 d-none d-md-block"
                    onClick={handleSearch}
                  >
                    <i className="fas fa-search" />
                  </button>
                  <input type="text" ref={searchRef} />
                  <button
                    type="button"
                    className="bg-transparent border-0 d-block d-md-none"
                    onClick={handleSearch}
                  >
                    <i className="fas fa-search" />
                  </button>
                </div>
                <Link to={contact.url} className="btn-contact">
                  {contact.name}
                </Link>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
