import React from 'react';
import { Helmet } from 'react-helmet';

const OneTrustScript = ({ oneTrustId }) => (
  <Helmet>
    <script
      type="text/javascript"
      src={`https://cdn.cookielaw.org/consent/${oneTrustId}/OtAutoBlock.js`}
    />
    <script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script={oneTrustId}
    />
    <script
      type="text/javascript"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: 'function OptanonWrapper() {}',
      }}
    />
  </Helmet>
);
export default OneTrustScript;
